import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Video from './components/Casos_de_Exito/Video';
import Footer from './components/Footer/Footer';
import Form from './components/Form/Form';
import NewHero from './components/Hero/NewHero';
import NewInfo from './components/Info/NewInfo';
import Services from './components/Services/Services';
import Inmobiliarias from './components/Slider/Inmobiliarias';
import Integraciones from './components/Slider/Integraciones';
import NavBar from './components/navbar/navbar';
import './index.css';

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <NewHero />
      <Integraciones />
      {/* <Info /> */}
      <NewInfo />
      {/* <Whatsapp /> */}
      <Services />
      <Inmobiliarias />
      <Video />
      <Form />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
