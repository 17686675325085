import React from 'react';

import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const data = [
  { name: 'Coordinar visita', value: 324 },
  { name: 'Contactar telefónicamente', value: 276 },
  { name: 'Enviar más información', value: 101 },
  { name: 'Envío Documentación Crediticia', value: 198 },
  { name: 'Posventa', value: 189 },
];

const COLORS = ['#015ca3', '#0070c1', '#0c94eb', '#36aefa', '#7cc9fd'];
const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);

const CustomTooltip = ({ active, payload }: { active: boolean; payload: { name: string; value: number }[] }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    return (
      <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 text-start">
        <p className="text-sm font-bold text-gray-700">{name}</p>
        <p className="text-sm text-gray-600 mt-1">
          Clientes: <span className="font-medium">{value}</span>
        </p>
        <p className="text-sm text-gray-600">
          Porcentaje: <span className="font-medium">{((value / totalValue) * 100).toFixed(2)}%</span>
        </p>
      </div>
    );
  }
  return null;
};

const AccionesDonutChart = () => {
  return (
    <div className="w-full lg:w-1/3 mx-auto rounded-3xl shadow-lg overflow-hidden text-center px-2 py-5 border-2">
      <h2 className="text-2xl font-bold text-gray-800 pb-5">Tareas Asignadas</h2>
      <div className="h-60">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie data={data} innerRadius={40} outerRadius={110} paddingAngle={0} dataKey="value" isAnimationActive>
              {data.map((entry, index) => (
                <Cell key={`cell-${index + 1}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              content={<CustomTooltip active={false} payload={[]} />}
              formatter={(value: number) => `${value} (${((value / totalValue) * 100).toFixed(2)}%)`}
              labelFormatter={(label) => `Acción: ${label}`}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AccionesDonutChart;
