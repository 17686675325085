import React from 'react';
import { render } from 'react-dom';

import App from './App.js';
import './index.css';

const root = document.getElementById('lidz');
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);
