import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const ButtonNavbar = ({ href, text, onClick }: { href: string; text: string; onClick: () => void }) => {
  return (
    <ScrollLink
      to={href}
      smooth
      duration={800}
      offset={0}
      className="hidden md:flex lg:visible bg-primary py-3 px-9 rounded-3xl text-white transition-all
					duration-300 hover:scale-105 text-md cursor-pointer w-fit font-semibold
					hover:bg-palete"
      onClick={onClick}
    >
      <span>{text}</span>
    </ScrollLink>
  );
};

export default ButtonNavbar;
