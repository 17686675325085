import React, { useRef, useState, useEffect } from 'react';

import { gsap } from 'gsap';

const NewForm = () => {
  const [showNewSection, setShowNewSection] = useState(false);
  const newSectionRef = useRef(null);

  const handleButtonClick = () => {
    setShowNewSection(true);
  };

  const handleCloseClick = () => {
    gsap.to(newSectionRef.current, {
      x: '100%',
      opacity: 0,
      duration: 1,
      ease: 'power3.inOut',
      onComplete: () => setShowNewSection(false),
    });
  };

  useEffect(() => {
    if (showNewSection) {
      document.body.style.overflow = 'hidden';

      gsap.fromTo(
        newSectionRef.current,
        { x: '100%', opacity: 0 },
        { x: 0, opacity: 1, duration: 1, ease: 'power3.out' }
      );
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showNewSection]);

  return (
    <>
      <section className="md:flex xs:mx-5 lg:h-dvh pt-28 xs:pt-10 mb-12 xl:ml-20 xxl:ml-52 xxl:mr-16" id="form">
        <div className="form-text-container flex flex-col">
          <h1 className="text-5xl mt-3 font-medium xs:text-3xl">
            Somos una herramienta personalizada para tu <br />
            proyecto
          </h1>
          <p className="my-10 text-xl xs:text-xs">
            Nuestra tecnología es única, y buscamos relaciones <br /> de largo plazo. Queremos ser parte de tu equipo y
            en conjunto <br /> mejorar la productividad de tus procesos. Te invitamos a ser parte <br /> de esta
            revolución.
          </p>
          <button
            onClick={handleButtonClick}
            type="button"
            className="flex bg-primary py-4 px-12 rounded-3xl text-white transition-all duration-300 hover:scale-105 text-md cursor-pointer w-fit font-semibold hover:bg-palete"
          >
            Agenda en el calendario
          </button>
        </div>
        <div className="w-full -ml-5">
          <iframe
            title="Formulario de Lidz"
            src="https://forms.monday.com/forms/embed/dc688625f91ba00e4935570fa1bbd4e6?r=use1"
            // width="96%"
            // height="950"
            className="w-[96%] xs:w-[110%] xs:mt-0 h-[950px] sm:h-[800px] md:h-[700px] lg:h-[850px] xl:h-[950px] border-none -mt-10" // style={{
            //   position: 'relative',
            //   bottom: '60px',
            //   overflowY: 'hidden',
            // }}
            sandbox="allow-scripts allow-same-origin allow-forms"
          />
        </div>
      </section>

      {showNewSection && (
        <section ref={newSectionRef} className="fixed top-0 right-0 md:w-2/4 bg-white pl-20 xs:pr-10">
          <iframe
            title="Agendar en el calendario"
            src="https://zcal.co/i/1YUoNPVA?embed=1&embedType=iframe"
            loading="lazy"
            className="w-full xs:w-[110%] min-h-[900px] max-w-[550px] min-w-[350px] p-0 overflow-hidden mt-[50px] z-[10]"
            // style={{
            //   width: '100%',
            //   minHeight: '950px',
            //   maxWidth: '550px',
            //   minWidth: '350px',
            //   padding: '0',
            //   overflow: 'hidden',
            //   marginTop: '90px',
            //   zIndex: '10',
            // }}
            id="zcal-invite"
          />
          <button
            className="size-14 absolute right-52 top-24 bg-monday rounded-full z-50 text-white cursor-pointer font-medium"
            onClick={handleCloseClick}
            type="button"
          >
            X
          </button>
          <div className="bg-white right-72 top-[5.5em] w-[120px] h-12 z-[100] absolute" />
        </section>
      )}
    </>
  );
};

export default NewForm;
