import React from 'react';

import QualifiedLeadsChart from '../Graphic/Area/QualifiedLeadsChart';
import LeadChart from '../Graphic/Charts/LeadChart';
import CanalesDonutChart from '../Graphic/Donuts/CanalesDonutChart';
import TareasDonutChart from '../Graphic/Donuts/TareasDonusChart';

const NewInfo = () => {
  return (
    <section className="md:flex xxl:mx-52 xl:mx-20 my-32 mx-5 xs:my-0" id="diferent">
      <div className="">
        <h2 className="text-4xl font-medium xs:text-3xl">
          ¿Por qué somos <span className="text-primary">diferentes?</span>
        </h2>
        <p className="text-base font-medium mt-10 mb-20 xs:text-sm xs:my-5 ">
          Conocemos a fondo la industria y entendemos tus desafíos. Nos integramos en cada etapa del recorrido de tu
          cliente, desde el primer contacto hasta la posventa. Colaboramos contigo para diseñar una experiencia de venta
          consultiva para tu empresa.
          <span className="text-primary"> Tu dolor, es nuestro desafío </span>
          tareas complejas y repetitivas. Cada empleado se integra a la perfección con tu CRM, completando campos
          automáticamente y manteniendo tus datos siempre al día.
        </p>
        <div className="flex gap-8 mb-10 xs:flex-wrap">
          <CanalesDonutChart />
          <TareasDonutChart />
          <QualifiedLeadsChart />
        </div>
        <div className="">
          <LeadChart />
        </div>
      </div>
      <aside className="flex lg:flex-col justify-between lg:pl-10 flex-wrap">
        <p className="flex justify-center items-center text-center px-10 border-2 lg:text-2xl h-[150px] w-[180px] xxs:w-[160px] mt-5 font-medium rounded-3xl lg:w-[300px] bg-white shadow-xl hover:shadow-2xl cursor-pointer lg:h-[200px]">
          Alertas de oportunidad y de problemas de gestión
        </p>
        <p className="flex justify-center items-center text-center px-10 border-2 lg:text-2xl h-[150px] w-[180px] xxs:w-[160px] mt-5 font-medium rounded-3xl lg:w-[300px] bg-white shadow-xl hover:shadow-2xl cursor-pointer lg:h-[200px]">
          Disminuir costos en Marketing
        </p>
        <p className="flex justify-center items-center text-center px-10 border-2 lg:text-2xl h-[150px] w-[180px] xxs:w-[160px] mt-5 font-medium rounded-3xl lg:w-[300px] bg-white shadow-xl hover:shadow-2xl cursor-pointer lg:h-[200px]">
          Conocimiento de tu cliente
        </p>
        <p className="flex justify-center items-center text-center px-10 border-2 lg:text-2xl h-[150px] w-[180px] xxs:w-[160px] mt-5 font-medium rounded-3xl lg:w-[300px] bg-white shadow-xl hover:shadow-2xl cursor-pointer lg:h-[200px]">
          Eficiencia en la fuerza de ventas
        </p>
      </aside>
    </section>
  );
};

export default NewInfo;
