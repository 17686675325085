import PlanOk from '../assets/images/Logo-Planok-color.png';
import Monday from '../assets/images/Monday-Com-Logo.png';
import Clientify from '../assets/images/clientify.png.webp';
import Constant_Contact from '../assets/images/constant_contact.svg';
import DataHunter from '../assets/images/data_hunter.webp';
import SCI from '../assets/images/e-core-logo-oscuro.webp';
import Hubspot from '../assets/images/hubspot_logo.svg';
import Pipedrive from '../assets/images/pipedrive_logo.svg';
import Salesforce from '../assets/images/salesforce_logo.svg';
import Sheets from '../assets/images/sheets_logo.svg';
import Zoho from '../assets/images/zoho_crm.webp';

export default [
  {
    id: 1,
    name: 'Monday',
    icon: Monday,
  },
  {
    id: 2,
    name: 'Salesforce',
    icon: Salesforce,
  },
  {
    id: 3,
    name: 'HubSpot',
    icon: Hubspot,
  },
  {
    id: 4,
    name: 'Pipedrive',
    icon: Pipedrive,
  },
  {
    id: 5,
    name: 'Google Sheet',
    icon: Sheets,
  },
  {
    id: 6,
    name: 'Zoho CRM',
    icon: Zoho,
  },
  {
    id: 7,
    name: 'Clientify',
    icon: Clientify,
  },
  {
    id: 8,
    name: 'SCI',
    icon: SCI,
  },
  {
    id: 9,
    name: 'Plan Ok',
    icon: PlanOk,
  },
  {
    id: 10,
    name: 'Constant Contact',
    icon: Constant_Contact,
  },
  {
    id: 11,
    name: 'Data Hunter',
    icon: DataHunter,
  },
];
