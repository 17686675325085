import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const ButtonPrincipal = ({
  href,
  text,
  onClick,
  className,
}: {
  href: string;
  text: string;
  onClick: () => void;
  className: string;
}) => {
  return (
    <ScrollLink
      to={href}
      smooth
      duration={800}
      offset={0}
      onClick={onClick}
      className={`flex bg-primary py-4 px-12 rounded-3xl text-white transition-all duration-300 hover:scale-105 text-md cursor-pointer w-fit font-semibold hover:bg-palete ${className}`}
    >
      <span>{text}</span>
    </ScrollLink>
  );
};

export default ButtonPrincipal;
