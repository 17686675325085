import React from 'react';
import { IoLogoLinkedin } from 'react-icons/io5';

// import logo from '../assets/images/logo-long.png';
// import ButtonPrincipal from '../Button/ButtonPrincipal/ButtonPrincipal';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="xxl:mx-52 xl:mx-20 xs:mx-5">
      {/* <section className="pb-36">
        <div className="flex flex-col gap-8 mr-8">
          <img src={logo} alt="" className="w-52" />
          <ButtonPrincipal text="¡Hablemos!" />
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-xl font-semibold mb-4">Mapa del sitio</h3>
          <a href="#">Inicio</a>
          <a>Partners</a>
          <a>Blog</a>
          <a>API</a>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-xl font-semibold mb-4">Industrias</h3>
          <a>Inmobiliarios</a>
          <a>Servicios</a>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-xl font-semibold mb-4">Legales</h3>
          <a>Politicas de privacidad</a>
          <a>Terminos de uso</a>
          <a>NDA</a>
        </div>
      </section> */}
      <hr />
      <section>
        <div className="flex gap-4">
          {/* <div className="flex items-center cursor-pointer">
						<a
							href="https://maps.app.goo.gl/g5oDRkczpyP8yZ2p8"
							target="_blank"
							className="flex gap-1">
							<FaMapMarkerAlt className="text-xl" />
							<span className="font-bol">Chile</span>
						</a>
					</div> */}
          <a
            href="https://www.linkedin.com/company/lidz-ai/posts/?feedView=all"
            target="_blank"
            rel="noreferrer"
            aria-label="LinkedIn"
          >
            <IoLogoLinkedin className="text-3xl text-primary cursor-pointer" />
          </a>
        </div>

        <a
          href="https://johanromero.vercel.app"
          target="_blank"
          className="flex items-center xs:text-xs"
          rel="noreferrer"
        >
          Copyright &copy; 2024 Lidz AI
        </a>
      </section>
    </footer>
  );
};

export default Footer;
