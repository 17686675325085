import React from 'react';

import ccs from '../../assets/images/CCS.png';
import cmf from '../../assets/images/CMF.png';
import logo from '../../assets/images/Diseño_sin_título-removebg-preview.png';
import google from '../../assets/images/Google.png';
import plankok from '../../assets/images/PlanOK.webp';
import sii from '../../assets/images/SII.png';
import afc from '../../assets/images/afc.svg';
import clave_unica from '../../assets/images/clave_unica.svg';
import hubspot from '../../assets/images/hubspot.webp';
import mercadopago from '../../assets/images/mercadolibre.png';
import meta from '../../assets/images/meta.png';
import monday from '../../assets/images/monday_.png';
import pipedrive from '../../assets/images/pipedrive.png';
import salesforce from '../../assets/images/salesforce.png';
import toctoc from '../../assets/images/toctoc.png';
import web from '../../assets/images/web.png';
import './Info.css';

const Info = () => {
  return (
    <figure className="relative hidden lg:flex xs:w-[200px] xs:h-[200px] lg:w-[360px] lg:h-[350px] items-center justify-center -top-[80px] ">
      <div className="pulse drop-shadow-lg w-[100px] h-[100px] xs:size-[20px] bg-primary rounded-full absolute">
        <img src={logo} alt="" loading="lazy" className="brightness-150" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-20 xs:size-10 bg-primary rounded-full absolute top-[-70px] left-[20%] transform -translate-x-[135px]">
        <img src={pipedrive} alt="" loading="lazy" className="w-20" />
      </div>
      <div className="flex justify-center items-center pulse drop-shadow-lg size-20 xs:size-10 bg-primary rounded-full absolute -bottom-[60px] left-[20%] transform -translate-x-[132px]">
        <img src={mercadopago} className="w-14" alt="" loading="lazy" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-[68px] xs:size-10 bg-palete rounded-full absolute -top-[70px] left-[62%] transform -translate-x-1/2">
        <img src={plankok} alt="" loading="lazy" className="w-12" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-14 xs:size-5 bg-primary rounded-full absolute -right-[63px] top-[28%] transform -translate-y-[130px]">
        <img src={toctoc} alt="" loading="lazy" className="w-10" />
      </div>
      <div className="flex items-center justify-center text-3xl text-white pulse drop-shadow-lg size-14 xs:size-5 bg-palete2 rounded-full absolute -bottom-[50px] left-[62%] transform -translate-x-1/2">
        dh
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-20 xs:size-10 bg-palete2 rounded-full absolute -left-[85px] top-[39%] transform -translate-y-1/2">
        <img src={google} alt="" loading="lazy" className="w-10" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-[86px] xs:size-9 bg-palete4 rounded-full absolute -left-[48px] top-[75%] transform translate-y-[50px]">
        <img src={web} alt="" loading="lazy" className="w-12" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-20 xs:size-10 bg-primary rounded-full absolute -right-[75px] top-[57%] transform -translate-y-1/2">
        <img src={salesforce} alt="" loading="lazy" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-14 xs:size-5 bg-palete4 rounded-full absolute top-4 -left-8">
        <img src={monday} alt="" loading="lazy" className="w-10" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-16 xs:size-8 bg-palete2 rounded-full absolute top-2 -right-6">
        <img src={hubspot} alt="" loading="lazy" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-20 xs:size-10 bg-palete2 rounded-full absolute -bottom-3 -right-2">
        <img src={meta} alt="" loading="lazy" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-14 xs:size-5 bg-palete2 rounded-full absolute top-[14%] left-[39%]">
        <img src={sii} alt="" loading="lazy" className="w-[28px]" />
      </div>
      <div className="flex items-center pulse drop-shadow-lg justify-center  size-14 xs:size-5 bg-palete2 rounded-full absolute top-[33%] right-[14%]">
        <img src={cmf} alt="" loading="lazy" className="w-10" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-14 xs:size-5 bg-palete2 rounded-full absolute bottom-[12%] left-[31%]">
        <img src={afc} alt="" loading="lazy" className="w-12" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-16 xs:size-8 bg-primary rounded-full absolute bottom-[19%] right-[20%]">
        <img src={clave_unica} alt="" className="w-14" />
      </div>
      <div className="flex items-center justify-center pulse drop-shadow-lg size-14 xs:size-5 bg-palete rounded-full absolute left-[12%] top-[40%] transform -translate-y-[1/2]">
        <img src={ccs} alt="" loading="lazy" className="w-14" />
      </div>
      <div className="w-[600px] xs:w-28 border-t-2 border-dashed border-gray-200 rotate-[70deg] -z-10" />
      <div className="w-[400px] xs:w-12 border-t-2 border-dashed border-gray-200 rotate-[110deg] -z-10 absolute" />
      <div className="w-[400px] xs:w-12 border-t-2 border-dashed border-gray-200 rotate-[171deg] top-40 -z-10 absolute" />
      <div className="w-[400px] xs:w-12 border-t-2 border-dashed border-gray-200 rotate-[27deg] top-[150px] -z-10 absolute" />
      <div className="w-[420px] xs:w-12 border-t-2 border-dashed border-gray-200 rotate-[322deg] top-[170px] -z-10 absolute" />
      <div className="size-[430px] xs:size-12 rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[200px] xs:size-10 rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[430px] xs:size-12 rotate-180 rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[200px] xs:size-10 rotate-[182deg] rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[430px] xs:size-12 rotate-[91deg] rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[200px] xs:size-10 rotate-90 rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[430px] xs:size-12 -rotate-[94deg] rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
      <div className="size-[200px] xs:size-10 -rotate-[93deg] rounded-[100%] border-t-2 border-dashed border-gray-200 -z-20 absolute" />
    </figure>
  );
};

export default Info;
