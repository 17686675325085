/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
// import React, { useRef, useState } from 'react';
// import { PiPlayCircleFill } from 'react-icons/pi';
import React from 'react';

import exito_buydepa from '../../assets/images/casos_de_exito_buydepa.jpg';
import exito_manquehue from '../../assets/images/casos_de_exito_manquehue.png';
import exito_sip from '../../assets/images/casos_de_exito_sip.jpeg';
// import buydepa from '../../assets/video/Buydepa 2024 beneficios Lidz.mp4';
// import buydepa2 from '../../assets/video/Buydepa_2024 intro y cifras.mp4';
import './Video.css';

// Define el componente como un Functional Component con TypeScript
const Video: React.FC = () => {
  return (
    <section
      className="video-container flex-col m-5 xs:flex xs:flex-col flex gap-10 xl:my-32 xxl:mx-52 xl:mx-20"
      id="success"
    >
      <h2 className="text-4xl xxl:mx-0 xl:mx-20 font-medium xs:hidden">
        Casos de<span className="text-primary"> éxitos</span>
      </h2>
      <div className="flex xs:flex-col md:flex- justify-between gap-10">
        <div className="bg-white border-2 rounded-3xl w-1/3 xs:w-auto hover:shadow-xl transition-shadow shadow-md duration-300">
          <img
            src={exito_manquehue}
            alt="Casos de Exito Manquehue"
            className="rounded-3xl drop-shadow-md w-full h-[250px] object-cover p-1"
          />
          <h1 className="2xl:text-xl font-medium mt-5 px-3 pb-5">Casos de éxito Manquehue</h1>
          <ul className="list-disc pl-5 space-y-2 mx-5 xl:h-[290px] 2xl:h-[220px] xs:mb-5">
            <li>Aumentaron la tasa de contactabilidad a más del 95%.</li>
            <li>
              Pasaron de 2 mil leads a 6 mil leads sin aumentar la fuerza de venta, siendo todos contactados al
              instante.
            </li>
            <li>Más del 55% de los clientes son contactados fuera de horario laboral.</li>
            <li>Mejor conocimiento del cliente en base a data.</li>
          </ul>
          <div className="flex flex-col mx-5 mt-3 justify-between items-center">
            <p className="font-semibold">Resultados en tan sólo 7 meses</p>
            <a
              href="https://www.linkedin.com/pulse/caso-de-%C3%A9xito-lidzai-inmobiliaria-manquehue-lidz-ai-hihvc/?trackingId=bmwPCw%2B%2FQkWCzCrnY%2BMQig%3D%3D"
              className="px-6 py-3 mt-5 mb-7 bg-primary text-white cursor-pointer rounded-2xl hover:scale-105 hover:bg-palete transition-all duration-300 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              Leer caso
            </a>
          </div>
        </div>
        <div className="bg-white border-2 rounded-3xl w-1/3 xs:w-auto hover:shadow-xl transition-shadow shadow-md duration-300">
          <img
            src={exito_buydepa}
            alt="Casos de Exito Buydepa"
            className="rounded-3xl drop-shadow-md w-full h-[250px] object-cover p-1"
          />
          <h1 className="2xl:text-xl font-medium mt-5 px-3 pb-5">Casos de éxito Buydepa</h1>
          <ul className="list-disc pl-5 space-y-2 mx-5 xl:h-[290px] lg:h-[280px] 2xl:h-[220px] xs:mb-5">
            <li>3X aumento de la tasa de clientes oportunidad.</li>
            <li>$5.000 costo de marketing por lead calificado.</li>
            <li>1 minuto tiempo de contacto.</li>
            <li>Atención instantánea dentro y fuera de horario laboral.</li>
          </ul>
          <div className="flex flex-col mx-5 mt-3 justify-between items-center">
            <p className="font-semibold">Resultados en tan sólo 5 meses</p>
            <a
              href="https://www.linkedin.com/pulse/caso-de-%25C3%25A9xito-lidzai-buydepa-lidz-ai-63awe/?trackingId=alBjBmm4R8CnXLHGtowWfg%3D%3D"
              className="px-6 py-3 mt-5 mb-7 bg-primary text-white rounded-2xl hover:scale-105 hover:bg-palete transition-all duration-300 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              Leer caso
            </a>
          </div>
        </div>
        <div className="bg-white border-2 rounded-3xl w-1/3 xs:w-auto hover:shadow-xl transition-shadow shadow-md duration-300">
          <img
            src={exito_sip}
            alt="Casos de Exito SIP"
            className="rounded-3xl drop-shadow-md w-full h-[250px] object-cover p-1"
          />
          <h1 className="2xl:text-xl font-medium mt-5 px-3 pb-5">Casos de éxito SIP</h1>
          <ul className="list-disc pl-5 space-y-2 mx-5 xl:h-[290px] lg:h-[280px] 2xl:h-[220px] xs:mb-5">
            <li>100% de los leads contactados con una tasa de respuesta de más del 60%.</li>
            <li>48% de los leads calificados agendó visita o se derivó al vendedor.</li>
            <li>1 minuto de tiempo de contacto promedio.</li>
            <li>Atención instantánea dentro y fuera de horario laboral.</li>
          </ul>
          <div className="flex flex-col mx-5 mt-3 justify-between items-center">
            <p className="font-semibold">Resultados en tan sólo 4 meses</p>
            <a
              href="https://www.linkedin.com/pulse/caso-de-%C3%A9xito-lidzai-sip-lidz-ai-jmwbe/?trackingId=E1Zsy6EyuE%2FSXvdw7XSv1w%3D%3D"
              className="px-6 py-3 mt-5 mb-7 bg-primary text-white cursor-pointer rounded-2xl hover:scale-105 hover:bg-palete transition-all duration-300 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              Leer caso
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
